import React, { useState } from 'react';
//gatsby
import { Link, graphql } from 'gatsby';
//seo
import Seo from 'gatsby-plugin-wpgraphql-seo';
//styles
import styled from 'styled-components';
import { rem, darken } from 'polished';
import vars from 'components/styles/varss';
//components
import Layout from 'components/ui/layout';
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';
import Icon from 'components/ui/icon';
import Small from 'components/resources/small';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//parser
import parse from "html-react-parser";

//styled
const ArticleArchiveWrap = styled.div`
  position: relative;
  .small-post {
    margin-bottom: ${rem('100px')};
    @media (max-width: ${vars.media.smMax}) {
      margin-bottom: ${rem('50px')};
    }
  }
`;

const ArticleArchiveHeader = styled.div`
  position: relative;
  padding-top: ${rem('60px')};
  padding-bottom: ${rem('100px')};
  margin-bottom: ${rem('60px')};
  background-color: ${vars.colors.darkBlue};
  color: #fff;
  text-align: center;
  overflow: hidden;
  @media (max-width: ${vars.media.mdMax}) {
    padding-bottom: ${rem('80px')};
    margin-bottom: ${rem('60px')};
  }
  p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .section-shape {
    width: 40%;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateX(10%) translateY(70%);
    @media (max-width: ${vars.media.mdMax}) {
      display: none;
    }
  }
`;

const ArticleNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: ${rem('48px')};
    height: ${rem('48px')};
    margin-left: ${rem('10px')};
    margin-right: ${rem('10px')};
    border-radius: 50%;
    color: #fff;
    background-color: ${vars.colors.darkBlue};
    &:hover {
      background-color: #6F54F9;
      color: #fff;
    }
    &:not([href]):not([tabindex]) {
      color: ${vars.colors.grey4};
      background-color: ${vars.colors.grey1};
      &:hover {
        color: ${vars.colors.grey4};
      }
    }
  }
  strong {
    display: inline-block;
    margin-left: ${rem('10px')};
    margin-right: ${rem('10px')};
  }
`;

const SearchWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${rem('40px')};
  @media (max-width: ${vars.media.smMax}) {
    justify-content: center;
  }
`;

const SearchForm = styled.div`
  position: relative;
  width: ${rem('392px')};
  input {
    display: block;
    height: ${rem('60px')};
    border-radius: 12px;
    background-color: #f4f4f6;
    border: 1px solid #f4f4f6;
    padding: ${rem('16px')} ${rem('110px')} ${rem('16px')} ${rem('16px')};
    color: ${vars.colors.text};
    transition: ${vars.transitions.hover1};
    width: 100%;
    &:focus,
    &:active {
      border-color: ${vars.colors.grey2};
      outline: none;
    }
  }
  button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    color: ${vars.colors.text};
    background-color: ${vars.colors.purple};
    font-weight: 700;
    height: ${rem('44px')};
    padding: ${rem('10px')} ${rem('16px')};
    border-radius: 12px;
    position: absolute;
    top: 8px;
    right: 8px;
    &:hover,
    &:focus,
    &:active {
      color: ${vars.colors.darkBlue};
      background-color: ${darken(0.05, vars.colors.purple)};
    }
  }
`;

const ArticleCTA = styled.div`
  position: relative;
  margin-top: ${rem('100px')};
`;

const ArticleArchive = ({ 
  navigate,
  data, 
  pageContext: { 
    nextPagePath,
    previousPagePath,
    articlesTotalPages,
    pageNumber 
  } }) => {

  const articles = data.allWpArticle.nodes;

  //search stuff
  const [search, setSearch] = useState('');

  const onSearch = (value) => {
    setSearch(value);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/search", { state: { searchKey: search } })
  };

  if (!articles.length ) {
    return (
      <Layout
        isResources={true}
        headerBg={'#ffffff'}
        headerColor={'#071A2B'}>
        <Seo
          title={data.wp.blogInfo.blog_seo_title}
          meta={[{
            name: `description`,
            content: data.wp.blogInfo.blog_seo_description,
          },]}
        />
        <ArticleArchiveWrap>
          <ArticleArchiveHeader>
          <Container>
            <Heading as="h1" heading1>{data.wp.blogInfo.blog_title}</Heading>
            <p>{data.wp.blogInfo.blog_subtitle}</p>
          </Container>
          <div className="section-shape">
            <div className="shape-wrapper">
              <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="93.15 278.8 1156.52 793.03">
                <path vectorEffect="non-scaling-stroke" d="M521.703 739.584C521.543 759.972 526.75 780.459 537.311 798.742L660.752 1012.47C681.582 1048.55 720.101 1070.79 761.762 1070.79L1008.58 1070.83C1050.24 1070.83 1088.78 1048.61 1109.64 1012.48L1233.04 798.742C1253.87 762.664 1253.89 718.207 1233.04 682.053L1109.6 468.321C1088.77 432.242 1050.25 410.003 1008.59 410.003L960.863 410.01" stroke="#907AFF" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path vectorEffect="non-scaling-stroke" d="M960.817 409.981L761.773 409.965C720.112 409.965 681.574 432.183 660.716 468.309L537.312 682.052C527.035 699.852 521.834 719.689 521.657 739.556" stroke="#ffffff" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path vectorEffect="non-scaling-stroke" d="M521.704 739.585L838.774 739.606C867.783 739.639 894.58 724.167 909.083 699.046L994.944 550.331C1009.45 525.21 1009.45 494.267 994.915 469.161L960.789 410.031" stroke="#ffffff" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path vectorEffect="non-scaling-stroke" d="M960.816 409.982L909.062 320.386C894.529 295.28 867.733 279.81 838.772 279.804L261.192 279.816C232.182 279.783 205.386 295.254 190.882 320.375L105.021 469.091C90.5178 494.212 90.5171 525.154 105.051 550.26L190.93 698.987C205.464 724.093 232.26 739.563 261.221 739.569L521.704 739.584" stroke="#1BB3F7" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path vectorEffect="non-scaling-stroke" d="M967.693 417.077C965.191 421.411 959.65 422.896 955.316 420.394C950.982 417.891 949.497 412.35 951.999 408.016C954.501 403.682 960.043 402.197 964.377 404.699C968.711 407.202 970.196 412.743 967.693 417.077Z" fill="#ffffff" />
                <path vectorEffect="non-scaling-stroke" d="M528.58 746.681C526.078 751.015 520.536 752.5 516.203 749.998C511.869 747.496 510.384 741.954 512.886 737.62C515.388 733.286 520.93 731.801 525.264 734.303C529.597 736.806 531.082 742.347 528.58 746.681Z" fill="#ffffff" />
              </svg>
            </div>
          </div>
        </ArticleArchiveHeader>
          <Container>
            <Heading as="h2" heading2 marginBottom="60px" style={{ textAlign: 'center' }}>No articles found.</Heading>
          </Container>
        </ArticleArchiveWrap>
      </Layout>
    )
  }

  return (
    <Layout
      isResources={true}
      headerBg={'#071A2B'}
      headerColor={'#ffffff'}>
      <Seo
        title={data.wp.blogInfo.blog_seo_title}
        meta={[{
          name: `description`,
          content: data.wp.blogInfo.blog_seo_description,
        },]}
      />
      <ArticleArchiveWrap>
        <ArticleArchiveHeader>
          <Container>
            <Heading as="h1" heading1>{data.wp.blogInfo.blog_title}</Heading>
            <p>{data.wp.blogInfo.blog_subtitle}</p>
          </Container>
          <div className="section-shape">
            <div className="shape-wrapper">
              <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="93.15 278.8 1156.52 793.03">
                <path vectorEffect="non-scaling-stroke" d="M521.703 739.584C521.543 759.972 526.75 780.459 537.311 798.742L660.752 1012.47C681.582 1048.55 720.101 1070.79 761.762 1070.79L1008.58 1070.83C1050.24 1070.83 1088.78 1048.61 1109.64 1012.48L1233.04 798.742C1253.87 762.664 1253.89 718.207 1233.04 682.053L1109.6 468.321C1088.77 432.242 1050.25 410.003 1008.59 410.003L960.863 410.01" stroke="#907AFF" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path vectorEffect="non-scaling-stroke" d="M960.817 409.981L761.773 409.965C720.112 409.965 681.574 432.183 660.716 468.309L537.312 682.052C527.035 699.852 521.834 719.689 521.657 739.556" stroke="#ffffff" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path vectorEffect="non-scaling-stroke" d="M521.704 739.585L838.774 739.606C867.783 739.639 894.58 724.167 909.083 699.046L994.944 550.331C1009.45 525.21 1009.45 494.267 994.915 469.161L960.789 410.031" stroke="#ffffff" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path vectorEffect="non-scaling-stroke" d="M960.816 409.982L909.062 320.386C894.529 295.28 867.733 279.81 838.772 279.804L261.192 279.816C232.182 279.783 205.386 295.254 190.882 320.375L105.021 469.091C90.5178 494.212 90.5171 525.154 105.051 550.26L190.93 698.987C205.464 724.093 232.26 739.563 261.221 739.569L521.704 739.584" stroke="#1BB3F7" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path vectorEffect="non-scaling-stroke" d="M967.693 417.077C965.191 421.411 959.65 422.896 955.316 420.394C950.982 417.891 949.497 412.35 951.999 408.016C954.501 403.682 960.043 402.197 964.377 404.699C968.711 407.202 970.196 412.743 967.693 417.077Z" fill="#ffffff" />
                <path vectorEffect="non-scaling-stroke" d="M528.58 746.681C526.078 751.015 520.536 752.5 516.203 749.998C511.869 747.496 510.384 741.954 512.886 737.62C515.388 733.286 520.93 731.801 525.264 734.303C529.597 736.806 531.082 742.347 528.58 746.681Z" fill="#ffffff" />
              </svg>
            </div>
          </div>
        </ArticleArchiveHeader>
        <Container>
          <SearchWrap>
            <SearchForm>
              <form onSubmit={handleSubmit}>
                <input type="text" placeholder="Search" defaultValue={search} onKeyUp={(e) => (onSearch(e.target.value))} />
                <button type="submit">
                  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M2 9a7 7 0 1 1 12.042 4.856 1.006 1.006 0 0 0-.186.186A7 7 0 0 1 2 9Zm12.618 7.032a9 9 0 1 1 1.414-1.414l3.675 3.675a1 1 0 0 1-1.414 1.414l-3.675-3.675Z" fill="#fff" />
                  </svg>
                </button>
              </form>
            </SearchForm>
          </SearchWrap>
          <Row>
            {articles.map((article) => (
              <Col col xs={12} sm={6} md={4} key={article.id}>
                <Small post={article} withCategory />
              </Col>
            ))}
          </Row>
          <ArticleNav>
            <Link to={previousPagePath}><Icon icon="caret-left" size={12} /></Link>
            <strong>{pageNumber} of {articlesTotalPages}</strong>
            <Link to={nextPagePath}><Icon icon="caret-right" size={12} /></Link>
          </ArticleNav>
        </Container>
        <ArticleCTA className="wp-front">
          {!!data.wp.blogCtas.articlesCTA && (
            parse(data.wp.blogCtas.articlesCTA)
          )}
        </ArticleCTA>
      </ArticleArchiveWrap>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($offset: Int!, $postsPerPage: Int!) {
    wp {
      readingSettings {
        postsPerPage
      }
      blogCtas {
        articlesCTA
      }
      blogInfo {
        blog_title
        blog_subtitle
        blog_seo_title
        blog_seo_description
      }
    }
    allWpArticle( 
      limit: $postsPerPage, 
      skip: $offset, 
      sort: {fields: date, order: DESC},
      filter: {status: {eq: "publish"}}
    ) {
      nodes {
        id
        title
        uri
        excerpt
        nodeType
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            sourceUrl
            srcSet
            altText
          }
        }
        articleCategories {
          nodes {
            uri
            name
            id
          }
        }
      }
    }
  }
`

export default ArticleArchive;